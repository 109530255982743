<template>
  <div>
    <div class="company-bg has-fixedbottom">
      <div class="container">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/home">首页</a></li>
            <li class="breadcrumb-item active" aria-current="page">
              安全文化专家
            </li>
          </ol>
        </nav>
        <!-- 轮播图 -->
        <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="false">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleCaptions" :data-bs-slide-to="index"
              :class="[index==curIdx?'active': '']" aria-current="true" aria-label="Slide 1"
              v-for="(item, index) in topList" :key="item.expert_id" @click="changeIndicator(index)"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active" v-for="(item) in topList" :key="item.expert_id">
              <div class="expert-card" @click="toExpertDetail(item.expert_id)">
                <img class="avatar" :src="item.avatar" mode="aspectFill" />
                <div class="expert-intro">
                  <div class="expert-info">
                    <span class="expert-name">{{item.real_name}}</span>
                    <span class="expert-job">{{item.gender=='1'?'男':'女'}} {{item.tag}}</span>
                  </div>
                  <div>
                    {{formatIntro(item.intro)}}
                  </div>
                </div>
                <img src="../../assets/company/icon-expert.png" class="icon-expet" />
                <div class="expert-shape"></div>
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions"
              data-bs-slide="prev" @click="curIdx==0?topList.length-1: curIdx--">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions"
              data-bs-slide="next" @click="curIdx==topList.length-1?0:curIdx++">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="company-title" bindtap="toExpertList">专家名录</div>
        <!-- 列表 -->
        <div class="panel">
          <div class="expert-list">
            <div class="expert-item" v-for="(item) in list" :key="item.expert_id" @click="toExpertDetail(item)">
              <img class="avatar" :src="item.avatar" />
              <span>{{ item.real_name }}</span>
            </div>
          </div>
          <div class=" company-more" @click="toExpertList">
            查看更多 <i class="iconfont icon-youjiantou"></i>
          </div>

        </div>
        <div hidden>
          <div class="company-title">答疑解惑</div>
          <div class="eq-list">
            <div class="eq-item">
              <div class="eq-title">
                使用撬装设备进行油气田零散气回收，是否需要办理许可证件？
              </div>
              <div class="eq-intro">
                <div class="eq-class">石油天然气</div>
                <div class="eq-expert">
                  <img class="avatar" src="/pages/images/ad.png" />
                  <span>粟镇宇</span>
                </div>
              </div>
              <i class="iconfont icon-canyuhuati"></i>
            </div>
            <div class="eq-item">
              <div class="eq-title">
                使用撬装设备进行油气田零散气回收，是否需要办理许可证件？
              </div>
              <div class="eq-intro">
                <div class="eq-class">石油天然气</div>
                <div class="eq-expert">
                  <img class="avatar" src="/pages/images/ad.png" />
                  <span>粟镇宇</span>
                </div>
              </div>
              <i class="iconfont icon-canyuhuati"></i>
            </div>
          </div>
          <div class="company-more">
            查看更多 <i class="iconfont icon-youjiantou"></i>
          </div>
        </div>
        <div class="fixed-bottom">
          <a class="bottom-btn" bindtap="toQForm"><i class="iconfont icon-tiwen1"></i>我要提问</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    expertList
  } from '@/network/API.js'
  import {
    Base64
  } from 'js-base64'
  export default {
    data() {
      return {
        list: [],
        topList: [],
        curIdx: 0,
      }
    },
    created() {
      this.getTopExpert()
      this.getRecomendExpert()
    },
    methods: {
      formatIntro(txt) {
        return Base64.decode(txt)
      },
      async getRecomendExpert() {
        let data = await expertList({
          is_top: 1
        })
        this.topList = data.list
      },
      async getTopExpert() {
        let data = await expertList({
          page_size: 10,
          page: 1,
          industry: "",
          city_code: "",
        })
        this.list = data.list
      },
      changeIndicator(index) {
        this.curIdx = index
      },
      toExpertList() {
        this.$router.push({
          name: "ExpertList"
        });
      },
      toExpertDetail(item) {
        this.$router.push({
          name: "ExpertDetail",
          params: {
            id: item.expert_id
          }
        });
      }
    }
  }
</script>
<style>
  .company-bg {
    padding-top: 30px;
    background-color: #fff;
    background-image: linear-gradient(180deg,
        #4189eb 0%,
        rgba(65, 137, 235, 0) 100%);
    background-size: 35% 65%;
    background-repeat: repeat-x;
  }

  .company-title {
    padding: 30px 0;
  }

  .panel {
    border-radius: 20px;
    background: linear-gradient(0deg,
        rgba(255, 255, 255, 0.42) 0%,
        rgba(255, 255, 255, 0.4) 0%,
        #ffffff 99%);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    padding: 30px;
  }

  .expert-list {
    display: flex;
    flex-wrap: wrap;
  }

  .expert-item {
    display: flex;
    width: 10%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }

  .company-more {
    padding: 0;
  }

  .avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 10px;
  }

  .eq-item {
    background: linear-gradient(111deg,
        rgba(191, 216, 255, 0.1) 5%,
        rgba(87, 159, 255, 0.1) 100%);
  }

  .carousel,
  .carousel-inner {
    width: 100%;
  }

  .expert-card {
    margin: 0;
    width: 100%;
    border-radius: 10px;
  }

  .expert-info {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .expert-info .expert-name {
    margin-right: 10px;
  }

  .breadcrumb-item.active,
  .breadcrumb-item a,
  .breadcrumb-item+.breadcrumb-item::before {
    color: #fff;
  }

  .icon-expet {
    opacity: 0.2;
    top: -50px;
    right: 0;
  }
</style>